<template>
  <div>
    <header>
      <Navbar />
    </header>
    <main>
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar'
import Footer from '@/components/common/Footer'
export default {
  name: 'News',
  components: {
    Navbar,
    Footer
  },
  metaInfo: {
    title: 'fasney 最新消息',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'fasney 與你分享，最新最優惠的訊息'
      }
    ]
  }
}
</script>
